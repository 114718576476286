import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ArrowDownBlack from "../assets/svg/arrow-down-black.svg";
import ExpandMore from "../assets/svg/expand_more-white.svg";
import TwitterIcon from "../assets/svg/subSiteTwitter.svg";
import FacebookIcon from "../assets/svg/subSiteFacebook.svg";
import InstagramIcon from "../assets/svg/subSiteInstagram.svg";
import YoutubeIcon from "../assets/svg/subSiteYoutube.svg";
import SibSiteChp from "../assets/img/subSiteCHP-Dikey-Logo.png";
import SubSitesearch from "../assets/svg/subSitesearch-24px.svg";
import SubSiteMustafaKemalAtaturk from "../assets/img/subSiteMustafaKemalAtaturk-1.png";
import SubSiteMustafaKemalAtaturkHq from "../assets/img/MustafaKemalAtaturk-1.png";
import OzgulOzel from "../assets/img/ozgur-ozel-2.jpg";
import SubsiteSearchIcon from "../assets/svg/subsite-search-icon.svg";
import Back24 from "../assets/svg/back-24px.svg";
import 'assets/css/subsite.css';
import 'assets/css/swiper-custom.css';
import SubVClose from "../assets/svg/subclose-24px.svg";


const HeaderComponent = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMykListVisible, setIsMykListVisible] = useState(false);
    const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);
    const [isMykMenuVisible, setIsMykMenuVisible] = useState(false);
    const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false);
    const [baseProvince, setBaseProvince] = useState('');
    const [districtOptions, setDistrictOptions] = useState([]);

    useEffect(() => {
        if (baseProvince) {
            fetchDistrictsByProvinceId(baseProvince);
        }
    }, [baseProvince]);

    const fetchDistrictsByProvinceId = async (provinceId) => {
        try {
            const response = await fetch(`https://partiokulu.chp.org.tr/base/${provinceId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const districts = await response.json();
            // Assuming the response is an array of districts
            setDistrictOptions(districts);
        } catch (error) {
            console.error('Failed to fetch districts:', error);
        }
    };
    const handleProvinceChange = (event) => {
        const provinceId = event.target.value;
        setBaseProvince(provinceId);
    };

    const openSubbannerDropdownMenu = () => {
        setIsDropdownMenuVisible(!isDropdownMenuVisible);
        setIsMykMenuVisible(false); // Assuming you want to hide the Myk menu when toggling the dropdown menu
        // Toggle body overflow and positioning
        setIsBodyOverflowHidden(!isBodyOverflowHidden);
        // if (!isBodyOverflowHidden) {
        //   document.body.style.overflow = "hidden";
        //   document.body.style.height = "100vh";
        //   document.body.style.position = "fixed";
        // } else {
        //   document.body.style.overflow = "scroll";
        //   document.body.style.height = "auto";
        //   document.body.style.position = "unset";
        // }
    };

    const subbannerMykMenu = () => {
        setIsMykMenuVisible(!isMykMenuVisible);
    };
    // Function to toggle the sub-banner dropdown menu
    const toggleSubbannerDropdownMenu = () => {
        setIsDropdownOpen(!isDropdownOpen);
        // Additional style changes can be handled via className changes and CSS instead of direct style manipulation
        if (!isDropdownOpen) {
            // Lock body scroll when the menu is open
            // document.body.style.overflow = "hidden";
            // document.body.style.height = "100vh";
            // document.body.style.position = "fixed";
        } else {
            // Re-enable body scroll when the menu is closed
            // document.body.style.overflow = "scroll";
            // document.body.style.height = "auto";
            // document.body.style.position = "unset";
        }
    };

    // Function to toggle between the main dropdown list and the MYK list
    const toggleMykMenu = () => {
        setIsMykListVisible(!isMykListVisible);
    };


    return (
        <>
            <header>
                <div className="banner-cage">
                    <div className="container">
                        <div className="row">
                            <div className="banner-container d-flex">
                                <div className="banner-container-subsites">
                                    <div className="banner-container-subsites-desktop">
                                        <div>
                                            <a
                                                href="https://chp.org.tr"
                                                target="_blank"
                                                className="banner link-button text"
                                            >
                                                CHP.ORG.TR
                                            </a>
                                        </div>
                                        <div className="line"/>
                                        <div
                                            className="banner-left-dropmenu"
                                            id="banner-left-dropmenu"
                                        >
                                            <a className="banner link-button text">MYK SİTELERİ</a>
                                            <div className="sub-banner-drop" id="banner-left-drop">
                                                <div className="sub-banner-dropdown d-flex flex-column">
                                                    <a
                                                        href="https://partiokulu.chp.org.tr"
                                                        target="_blank"
                                                    >
                                                        Parti Okulu
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"/>
                                        <div
                                            className="banner-right-dropmenu"
                                            id="banner-right-dropmenu"
                                        >
                                            <a className="banner link-button text">
                                                İL VE İLÇE BAŞKANLIKLARI
                                            </a>
                                            <div className="sub-banner-drop" id="banner-right-drop">
                                                <form
                                                    className="sub-banner-drop-content"
                                                    action="/base/router/subsite"
                                                    method="post"
                                                >
                                                    <div className="sub-banner-drop-content-top d-flex">
                                                        <h6 className="drop-first-title">İl Başkanlığı:</h6>
                                                        <span className="subsite-selectbox-arrow">
                              <img src={ArrowDownBlack}/>
                            </span>
                                                        <select
                                                            className="select-control"
                                                            id="baseProvince"
                                                            name="provinceID"
                                                            value={baseProvince}
                                                            onChange={handleProvinceChange}
                                                        >
                                                            <option>İl Seçiniz</option>
                                                            <option value="a7d87c28-4224-4d9a-bb2f-aad2cd3d9c0b">
                                                                ADANA
                                                            </option>
                                                            <option value="49ab9b75-5f5a-4b1e-aab7-949703e1c792">
                                                                ADIYAMAN
                                                            </option>
                                                            <option value="2f01c85a-11e7-4991-97b1-79d6ee1075ac">
                                                                AFYONKARAHİSAR
                                                            </option>
                                                            <option value="60602dea-4826-4de4-9315-54aef5ece3d2">
                                                                AĞRI
                                                            </option>
                                                            <option value="4846d275-aa2f-4fa7-b255-ca627f858cc0">
                                                                AKSARAY
                                                            </option>
                                                            <option value="55fa82eb-8025-4b34-a82e-6d42d49f0bad">
                                                                AMASYA
                                                            </option>
                                                            <option value="a10e49be-4e6f-4bc3-be07-5fbcb7fc5554">
                                                                ANKARA
                                                            </option>
                                                            <option value="01f631f5-4985-4bb1-91e9-8777266b9a46">
                                                                ANTALYA
                                                            </option>
                                                            <option value="049b84ec-02eb-41b0-a05e-27ece4e8cec8">
                                                                ARDAHAN
                                                            </option>
                                                            <option value="4e2b02c4-9c97-4160-91ec-5a9346e78600">
                                                                ARTVİN
                                                            </option>
                                                            <option value="b557512e-2377-445f-950d-965cbc3ceb66">
                                                                AYDIN
                                                            </option>
                                                            <option value="57350d2e-c31c-4b45-93ec-f8170239918f">
                                                                BALIKESİR
                                                            </option>
                                                            <option value="bb321f5e-5bdb-49a3-9ae4-22915cabcfe3">
                                                                BARTIN
                                                            </option>
                                                            <option value="e2b5c04d-aa7c-4244-865b-e008265700d1">
                                                                BATMAN
                                                            </option>
                                                            <option value="caa57817-5a9f-4f93-a19a-e57fffb5dd3e">
                                                                BAYBURT
                                                            </option>
                                                            <option value="c7eb58b5-ec38-4d53-895f-b89ca9585e68">
                                                                BİLECİK
                                                            </option>
                                                            <option value="9b92aeb5-a321-43fe-b42d-6f7ee105e379">
                                                                BİNGÖL
                                                            </option>
                                                            <option value="df5bdab8-1f13-47ac-947e-987bc7d04e8a">
                                                                BİTLİS
                                                            </option>
                                                            <option value="9633cb1b-23db-46b6-9199-fb0e298de820">
                                                                BOLU
                                                            </option>
                                                            <option value="4854e7db-024e-47f9-9045-5f78924656b1">
                                                                BURDUR
                                                            </option>
                                                            <option value="09e14107-dd33-40de-9737-68f01cfd8437">
                                                                BURSA
                                                            </option>
                                                            <option value="b41c1b32-9bb0-41fc-b6ad-37f275d74c68">
                                                                ÇANAKKALE
                                                            </option>
                                                            <option value="4d2f4be6-05e5-4b31-9467-38278c480c05">
                                                                ÇANKIRI
                                                            </option>
                                                            <option value="2a8479c8-5ed4-45ef-b1e4-be8fc52f8720">
                                                                ÇORUM
                                                            </option>
                                                            <option value="5a643485-11fb-4c73-b8df-0fc080492732">
                                                                DENİZLİ
                                                            </option>
                                                            <option value="1474b9d2-1e37-48c4-8736-878f1b1aa0a2">
                                                                DİYARBAKIR
                                                            </option>
                                                            <option value="3203ac59-e701-448b-91bb-4eaf229b14b6">
                                                                DÜZCE
                                                            </option>
                                                            <option value="ee48557b-2742-40d0-b51f-c10060abc287">
                                                                EDİRNE
                                                            </option>
                                                            <option value="ff732f56-a522-4856-a02f-be20c56287fb">
                                                                ELAZIĞ
                                                            </option>
                                                            <option value="12f7d185-b606-480a-bc02-ff5a8a662bc8">
                                                                ERZİNCAN
                                                            </option>
                                                            <option value="687eb704-fbf3-467e-ad77-55783d43a238">
                                                                ERZURUM
                                                            </option>
                                                            <option value="51a3b932-053c-41e7-bc58-5363b113d4c0">
                                                                ESKİŞEHİR
                                                            </option>
                                                            <option value="2c1a7e8a-142f-4891-8145-0554b81cb9ae">
                                                                GAZİANTEP
                                                            </option>
                                                            <option value="9fcf8d50-d67b-4991-b003-61bf7b56b165">
                                                                GİRESUN
                                                            </option>
                                                            <option value="b28536ea-7777-42a0-89e7-0c8a1456dfe8">
                                                                GÜMÜŞHANE
                                                            </option>
                                                            <option value="b43de411-f037-4435-93a6-22d9d5f4b0f9">
                                                                HAKKARİ
                                                            </option>
                                                            <option value="5077f87f-991f-49e8-80e7-678f688c6b2e">
                                                                HATAY
                                                            </option>
                                                            <option value="589f372e-5c6d-400a-bc58-35b04b372a8b">
                                                                IĞDIR
                                                            </option>
                                                            <option value="561cd0e0-025a-4e0d-8b9c-9aa8ebccf98e">
                                                                ISPARTA
                                                            </option>
                                                            <option value="fb8cd49d-ea74-49ee-8c79-c644f8d938f1">
                                                                İSTANBUL
                                                            </option>
                                                            <option value="5e6304ad-e2a1-4a27-8ec3-a13fe2006ba0">
                                                                İZMİR
                                                            </option>
                                                            <option value="fcba8699-0efd-4abe-bae5-89e4812e0a65">
                                                                KAHRAMANMARAŞ
                                                            </option>
                                                            <option value="1a3f2c43-5241-48dd-9dbf-3154c9dd9e2a">
                                                                KARABÜK
                                                            </option>
                                                            <option value="1fa07418-5b07-4109-8dae-b100abb148f3">
                                                                KARAMAN
                                                            </option>
                                                            <option value="31b520b4-d951-411e-8138-34ec16a87e31">
                                                                KARS
                                                            </option>
                                                            <option value="e7717e6e-7d56-4cf1-a4c8-9d61d50e6fed">
                                                                KASTAMONU
                                                            </option>
                                                            <option value="a00723e3-d73f-46ba-8c17-00dd917f0f9d">
                                                                KAYSERİ
                                                            </option>
                                                            <option value="66252267-6042-4dad-b554-57d156fa6497">
                                                                KIRIKKALE
                                                            </option>
                                                            <option value="111e8449-10c4-42ee-b0bf-53d2f0a4c991">
                                                                KIRKLARELİ
                                                            </option>
                                                            <option value="678b60de-04f9-4504-9bb7-b26295b65a54">
                                                                KIRŞEHİR
                                                            </option>
                                                            <option value="68d9dd5b-1c02-442b-a6c2-fa05c61f0712">
                                                                KİLİS
                                                            </option>
                                                            <option value="f729ee8e-a4bd-4b7d-9609-7273798bf650">
                                                                KOCAELİ
                                                            </option>
                                                            <option value="79b915db-11b6-4928-9e31-f2c20f20e803">
                                                                KONYA
                                                            </option>
                                                            <option value="abd5b80a-d32b-4b80-bc09-0772be5c2b5a">
                                                                KÜTAHYA
                                                            </option>
                                                            <option value="9de86f74-6079-4064-ba18-695d5cc10eda">
                                                                MALATYA
                                                            </option>
                                                            <option value="3ae909b9-c3ec-4aad-b9ce-f5e2d5113887">
                                                                MANİSA
                                                            </option>
                                                            <option value="6e520ad5-0dec-4b2e-9642-1b92f899327a">
                                                                MARDİN
                                                            </option>
                                                            <option value="0381c248-9f7b-44ae-9e2f-c1bf0dd82395">
                                                                MERSİN
                                                            </option>
                                                            <option value="bf3bb583-a36c-4b9f-a3d2-1d9899970071">
                                                                MUĞLA
                                                            </option>
                                                            <option value="5780ed37-af5f-4ca1-b27c-f0aa897e939e">
                                                                MUŞ
                                                            </option>
                                                            <option value="ec776cff-b50f-4a75-8726-f3f5209048fa">
                                                                NEVŞEHİR
                                                            </option>
                                                            <option value="3e1ff788-7543-40fb-b7b9-8a9bf3dcd371">
                                                                NİĞDE
                                                            </option>
                                                            <option value="1adb8bcc-7b05-41f7-b067-9bd2d007d932">
                                                                ORDU
                                                            </option>
                                                            <option value="871747bf-b0cd-498b-83d6-63219bc8bed5">
                                                                OSMANİYE
                                                            </option>
                                                            <option value="5c7b6598-ed29-449b-991a-97cc249c69fc">
                                                                RİZE
                                                            </option>
                                                            <option value="9e6f2bfd-c96c-4609-bbc5-95857175d35c">
                                                                SAKARYA
                                                            </option>
                                                            <option value="dcbf7400-dee4-411a-891c-8e045e24e167">
                                                                SAMSUN
                                                            </option>
                                                            <option value="2885bfcb-ac17-445d-b394-7831eafcc117">
                                                                SİİRT
                                                            </option>
                                                            <option value="e8bda3e2-1f54-4ebd-aa10-9c2e745acfc1">
                                                                SİNOP
                                                            </option>
                                                            <option value="018b7904-b7ad-4ac1-b9c6-739bdc10c55f">
                                                                SİVAS
                                                            </option>
                                                            <option value="916c1b17-a2a8-4c31-bc6c-99bda2815848">
                                                                ŞANLIURFA
                                                            </option>
                                                            <option value="ab864def-b6c4-4a1f-9962-79cc1ea31f52">
                                                                ŞIRNAK
                                                            </option>
                                                            <option value="00c894b4-f753-45db-89d0-c57ca9a68407">
                                                                TEKİRDAĞ
                                                            </option>
                                                            <option value="bfc65312-a462-446e-95c4-8f1bc541f0db">
                                                                TOKAT
                                                            </option>
                                                            <option value="15dec0b3-b352-4324-8cbf-9244a49e4e60">
                                                                TRABZON
                                                            </option>
                                                            <option value="622eb7f2-1e7f-4b35-a6a1-f4bf755a14e4">
                                                                TUNCELİ
                                                            </option>
                                                            <option value="7a61345e-5941-4974-b73d-52ee64230cd0">
                                                                UŞAK
                                                            </option>
                                                            <option value="6e1a3608-c1d4-4e4e-b354-bf04b42680a0">
                                                                VAN
                                                            </option>
                                                            <option value="acd39c2e-228e-4b05-adc9-68ca9240d8de">
                                                                YALOVA
                                                            </option>
                                                            <option value="8f5ad39d-a45e-4668-8c25-aed094c0f521">
                                                                YOZGAT
                                                            </option>
                                                            <option value="ab23d270-5f48-4dde-bc4e-8fb8a101d8f3">
                                                                ZONGULDAK
                                                            </option>
                                                        </select>
                                                        <button
                                                            className="btn btn-secondary btn-input "
                                                            type="submit"
                                                            id="baseSite"
                                                        >
                                                            SİTEYE GİT
                                                        </button>
                                                    </div>
                                                    <div className="sub-banner-drop-content-bottom d-flex">
                                                        <h6>İlçe Başkanlığı:</h6>
                                                        <span className="subsite-selectbox-arrow">
                              <img src={ArrowDownBlack}/>
                            </span>
                                                        <select
                                                            className="select-control"
                                                            id="baseDistrict"
                                                            name="districtID"
                                                        >
                                                            <option>İlçe Seçiniz</option>
                                                            {districtOptions.map((district) => (
                                                                <option key={district.id}
                                                                        value={district.provinceID}>{district.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <input
                                                        name="__RequestVerificationToken"
                                                        type="hidden"
                                                        defaultValue="CfDJ8LNUMPBFiTlPuImaoqqW5kIoi1epHl9L4dnFZ6oDL8EgfHZMX_damJcit5KX6SRRxv-LiFChso-iQEYJUQxRoeKZwDWVNebVhdOOqpYp7uRHRksSgNJkzU4vnWmiulRLfJQ4Dx4N-ZOWn_L2ZVNZutM"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="sub-banner-dropdown-tablet"
                                        className="banner-container-subsites-tablet"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="sub-banner-dropdown-title"
                                                onClick={openSubbannerDropdownMenu}
                                            >
                                                <div className="banner link-button text">
                                                    PARTİ SİTELERİ{" "}
                                                </div>
                                                <div
                                                    id="banner-container-subsites-tablet-down-arrow"
                                                    className="banner-container-subsites-tablet-down-arrow"
                                                />
                                                <div
                                                    id="banner-container-subsites-tablet-up-arrow"
                                                    className="banner-container-subsites-tablet-up-arrow"
                                                />
                                            </div>
                                            <div
                                                id="sub-banner-dropdown-tablet-menu"
                                                className={`sub-banner-drop ${isDropdownMenuVisible ? 'displayBlock' : 'displayNone'}`}
                                                style={{display: "none"}}
                                            >
                                                <div className="sub-banner-dropdown d-flex flex-column">
                                                    <div className="red-line"/>
                                                    <div
                                                        id="sub-banner-dropdown-list"
                                                        className="sub-banner-dropdown-list"
                                                    >
                                                        <a href="https://chp.org.tr/" target="_blank">
                                                            CHP.ORG.TR
                                                        </a>
                                                        <div
                                                            id="sub-banner-myk-menu"
                                                            onClick={subbannerMykMenu}
                                                        >
                                                            MYK SİTELERİ{" "}
                                                            <span>
                                <img
                                    src={ExpandMore}/>
                              </span>
                                                        </div>
                                                        <div>İL VE İLÇE BAŞKANLIKLARI</div>
                                                        <form
                                                            className="d-block p-0"
                                                            action="/base/router/subsite"
                                                            method="post"
                                                        >
                                                            <div className="row sub-banner-dropdown-row m-0">
                                                                <div className="col-sm-3 p-0 ">
                                                                    <div>İl Başkanlığı:</div>
                                                                </div>
                                                                <div
                                                                    className="col-sm-4 p-0 sub-banner-dropdown-selectbox-container">
                                  <span className="subsite-selectbox-arrow">
                                    <img
                                        src={ArrowDownBlack}/>
                                  </span>
                                                                    <select
                                                                        className="select-control w-100"
                                                                        id="baseTabletProvince"
                                                                        name="provinceID"
                                                                    >
                                                                        <option>İl Seçiniz</option>
                                                                        <option
                                                                            value="a7d87c28-4224-4d9a-bb2f-aad2cd3d9c0b">
                                                                            ADANA
                                                                        </option>
                                                                        <option
                                                                            value="49ab9b75-5f5a-4b1e-aab7-949703e1c792">
                                                                            ADIYAMAN
                                                                        </option>
                                                                        <option
                                                                            value="2f01c85a-11e7-4991-97b1-79d6ee1075ac">
                                                                            AFYONKARAHİSAR
                                                                        </option>
                                                                        <option
                                                                            value="60602dea-4826-4de4-9315-54aef5ece3d2">
                                                                            AĞRI
                                                                        </option>
                                                                        <option
                                                                            value="4846d275-aa2f-4fa7-b255-ca627f858cc0">
                                                                            AKSARAY
                                                                        </option>
                                                                        <option
                                                                            value="55fa82eb-8025-4b34-a82e-6d42d49f0bad">
                                                                            AMASYA
                                                                        </option>
                                                                        <option
                                                                            value="a10e49be-4e6f-4bc3-be07-5fbcb7fc5554">
                                                                            ANKARA
                                                                        </option>
                                                                        <option
                                                                            value="01f631f5-4985-4bb1-91e9-8777266b9a46">
                                                                            ANTALYA
                                                                        </option>
                                                                        <option
                                                                            value="049b84ec-02eb-41b0-a05e-27ece4e8cec8">
                                                                            ARDAHAN
                                                                        </option>
                                                                        <option
                                                                            value="4e2b02c4-9c97-4160-91ec-5a9346e78600">
                                                                            ARTVİN
                                                                        </option>
                                                                        <option
                                                                            value="b557512e-2377-445f-950d-965cbc3ceb66">
                                                                            AYDIN
                                                                        </option>
                                                                        <option
                                                                            value="57350d2e-c31c-4b45-93ec-f8170239918f">
                                                                            BALIKESİR
                                                                        </option>
                                                                        <option
                                                                            value="bb321f5e-5bdb-49a3-9ae4-22915cabcfe3">
                                                                            BARTIN
                                                                        </option>
                                                                        <option
                                                                            value="e2b5c04d-aa7c-4244-865b-e008265700d1">
                                                                            BATMAN
                                                                        </option>
                                                                        <option
                                                                            value="caa57817-5a9f-4f93-a19a-e57fffb5dd3e">
                                                                            BAYBURT
                                                                        </option>
                                                                        <option
                                                                            value="c7eb58b5-ec38-4d53-895f-b89ca9585e68">
                                                                            BİLECİK
                                                                        </option>
                                                                        <option
                                                                            value="9b92aeb5-a321-43fe-b42d-6f7ee105e379">
                                                                            BİNGÖL
                                                                        </option>
                                                                        <option
                                                                            value="df5bdab8-1f13-47ac-947e-987bc7d04e8a">
                                                                            BİTLİS
                                                                        </option>
                                                                        <option
                                                                            value="9633cb1b-23db-46b6-9199-fb0e298de820">
                                                                            BOLU
                                                                        </option>
                                                                        <option
                                                                            value="4854e7db-024e-47f9-9045-5f78924656b1">
                                                                            BURDUR
                                                                        </option>
                                                                        <option
                                                                            value="09e14107-dd33-40de-9737-68f01cfd8437">
                                                                            BURSA
                                                                        </option>
                                                                        <option
                                                                            value="b41c1b32-9bb0-41fc-b6ad-37f275d74c68">
                                                                            ÇANAKKALE
                                                                        </option>
                                                                        <option
                                                                            value="4d2f4be6-05e5-4b31-9467-38278c480c05">
                                                                            ÇANKIRI
                                                                        </option>
                                                                        <option
                                                                            value="2a8479c8-5ed4-45ef-b1e4-be8fc52f8720">
                                                                            ÇORUM
                                                                        </option>
                                                                        <option
                                                                            value="5a643485-11fb-4c73-b8df-0fc080492732">
                                                                            DENİZLİ
                                                                        </option>
                                                                        <option
                                                                            value="1474b9d2-1e37-48c4-8736-878f1b1aa0a2">
                                                                            DİYARBAKIR
                                                                        </option>
                                                                        <option
                                                                            value="3203ac59-e701-448b-91bb-4eaf229b14b6">
                                                                            DÜZCE
                                                                        </option>
                                                                        <option
                                                                            value="ee48557b-2742-40d0-b51f-c10060abc287">
                                                                            EDİRNE
                                                                        </option>
                                                                        <option
                                                                            value="ff732f56-a522-4856-a02f-be20c56287fb">
                                                                            ELAZIĞ
                                                                        </option>
                                                                        <option
                                                                            value="12f7d185-b606-480a-bc02-ff5a8a662bc8">
                                                                            ERZİNCAN
                                                                        </option>
                                                                        <option
                                                                            value="687eb704-fbf3-467e-ad77-55783d43a238">
                                                                            ERZURUM
                                                                        </option>
                                                                        <option
                                                                            value="51a3b932-053c-41e7-bc58-5363b113d4c0">
                                                                            ESKİŞEHİR
                                                                        </option>
                                                                        <option
                                                                            value="2c1a7e8a-142f-4891-8145-0554b81cb9ae">
                                                                            GAZİANTEP
                                                                        </option>
                                                                        <option
                                                                            value="9fcf8d50-d67b-4991-b003-61bf7b56b165">
                                                                            GİRESUN
                                                                        </option>
                                                                        <option
                                                                            value="b28536ea-7777-42a0-89e7-0c8a1456dfe8">
                                                                            GÜMÜŞHANE
                                                                        </option>
                                                                        <option
                                                                            value="b43de411-f037-4435-93a6-22d9d5f4b0f9">
                                                                            HAKKARİ
                                                                        </option>
                                                                        <option
                                                                            value="5077f87f-991f-49e8-80e7-678f688c6b2e">
                                                                            HATAY
                                                                        </option>
                                                                        <option
                                                                            value="589f372e-5c6d-400a-bc58-35b04b372a8b">
                                                                            IĞDIR
                                                                        </option>
                                                                        <option
                                                                            value="561cd0e0-025a-4e0d-8b9c-9aa8ebccf98e">
                                                                            ISPARTA
                                                                        </option>
                                                                        <option
                                                                            value="fb8cd49d-ea74-49ee-8c79-c644f8d938f1">
                                                                            İSTANBUL
                                                                        </option>
                                                                        <option
                                                                            value="5e6304ad-e2a1-4a27-8ec3-a13fe2006ba0">
                                                                            İZMİR
                                                                        </option>
                                                                        <option
                                                                            value="fcba8699-0efd-4abe-bae5-89e4812e0a65">
                                                                            KAHRAMANMARAŞ
                                                                        </option>
                                                                        <option
                                                                            value="1a3f2c43-5241-48dd-9dbf-3154c9dd9e2a">
                                                                            KARABÜK
                                                                        </option>
                                                                        <option
                                                                            value="1fa07418-5b07-4109-8dae-b100abb148f3">
                                                                            KARAMAN
                                                                        </option>
                                                                        <option
                                                                            value="31b520b4-d951-411e-8138-34ec16a87e31">
                                                                            KARS
                                                                        </option>
                                                                        <option
                                                                            value="e7717e6e-7d56-4cf1-a4c8-9d61d50e6fed">
                                                                            KASTAMONU
                                                                        </option>
                                                                        <option
                                                                            value="a00723e3-d73f-46ba-8c17-00dd917f0f9d">
                                                                            KAYSERİ
                                                                        </option>
                                                                        <option
                                                                            value="66252267-6042-4dad-b554-57d156fa6497">
                                                                            KIRIKKALE
                                                                        </option>
                                                                        <option
                                                                            value="111e8449-10c4-42ee-b0bf-53d2f0a4c991">
                                                                            KIRKLARELİ
                                                                        </option>
                                                                        <option
                                                                            value="678b60de-04f9-4504-9bb7-b26295b65a54">
                                                                            KIRŞEHİR
                                                                        </option>
                                                                        <option
                                                                            value="68d9dd5b-1c02-442b-a6c2-fa05c61f0712">
                                                                            KİLİS
                                                                        </option>
                                                                        <option
                                                                            value="f729ee8e-a4bd-4b7d-9609-7273798bf650">
                                                                            KOCAELİ
                                                                        </option>
                                                                        <option
                                                                            value="79b915db-11b6-4928-9e31-f2c20f20e803">
                                                                            KONYA
                                                                        </option>
                                                                        <option
                                                                            value="abd5b80a-d32b-4b80-bc09-0772be5c2b5a">
                                                                            KÜTAHYA
                                                                        </option>
                                                                        <option
                                                                            value="9de86f74-6079-4064-ba18-695d5cc10eda">
                                                                            MALATYA
                                                                        </option>
                                                                        <option
                                                                            value="3ae909b9-c3ec-4aad-b9ce-f5e2d5113887">
                                                                            MANİSA
                                                                        </option>
                                                                        <option
                                                                            value="6e520ad5-0dec-4b2e-9642-1b92f899327a">
                                                                            MARDİN
                                                                        </option>
                                                                        <option
                                                                            value="0381c248-9f7b-44ae-9e2f-c1bf0dd82395">
                                                                            MERSİN
                                                                        </option>
                                                                        <option
                                                                            value="bf3bb583-a36c-4b9f-a3d2-1d9899970071">
                                                                            MUĞLA
                                                                        </option>
                                                                        <option
                                                                            value="5780ed37-af5f-4ca1-b27c-f0aa897e939e">
                                                                            MUŞ
                                                                        </option>
                                                                        <option
                                                                            value="ec776cff-b50f-4a75-8726-f3f5209048fa">
                                                                            NEVŞEHİR
                                                                        </option>
                                                                        <option
                                                                            value="3e1ff788-7543-40fb-b7b9-8a9bf3dcd371">
                                                                            NİĞDE
                                                                        </option>
                                                                        <option
                                                                            value="1adb8bcc-7b05-41f7-b067-9bd2d007d932">
                                                                            ORDU
                                                                        </option>
                                                                        <option
                                                                            value="871747bf-b0cd-498b-83d6-63219bc8bed5">
                                                                            OSMANİYE
                                                                        </option>
                                                                        <option
                                                                            value="5c7b6598-ed29-449b-991a-97cc249c69fc">
                                                                            RİZE
                                                                        </option>
                                                                        <option
                                                                            value="9e6f2bfd-c96c-4609-bbc5-95857175d35c">
                                                                            SAKARYA
                                                                        </option>
                                                                        <option
                                                                            value="dcbf7400-dee4-411a-891c-8e045e24e167">
                                                                            SAMSUN
                                                                        </option>
                                                                        <option
                                                                            value="2885bfcb-ac17-445d-b394-7831eafcc117">
                                                                            SİİRT
                                                                        </option>
                                                                        <option
                                                                            value="e8bda3e2-1f54-4ebd-aa10-9c2e745acfc1">
                                                                            SİNOP
                                                                        </option>
                                                                        <option
                                                                            value="018b7904-b7ad-4ac1-b9c6-739bdc10c55f">
                                                                            SİVAS
                                                                        </option>
                                                                        <option
                                                                            value="916c1b17-a2a8-4c31-bc6c-99bda2815848">
                                                                            ŞANLIURFA
                                                                        </option>
                                                                        <option
                                                                            value="ab864def-b6c4-4a1f-9962-79cc1ea31f52">
                                                                            ŞIRNAK
                                                                        </option>
                                                                        <option
                                                                            value="00c894b4-f753-45db-89d0-c57ca9a68407">
                                                                            TEKİRDAĞ
                                                                        </option>
                                                                        <option
                                                                            value="bfc65312-a462-446e-95c4-8f1bc541f0db">
                                                                            TOKAT
                                                                        </option>
                                                                        <option
                                                                            value="15dec0b3-b352-4324-8cbf-9244a49e4e60">
                                                                            TRABZON
                                                                        </option>
                                                                        <option
                                                                            value="622eb7f2-1e7f-4b35-a6a1-f4bf755a14e4">
                                                                            TUNCELİ
                                                                        </option>
                                                                        <option
                                                                            value="7a61345e-5941-4974-b73d-52ee64230cd0">
                                                                            UŞAK
                                                                        </option>
                                                                        <option
                                                                            value="6e1a3608-c1d4-4e4e-b354-bf04b42680a0">
                                                                            VAN
                                                                        </option>
                                                                        <option
                                                                            value="acd39c2e-228e-4b05-adc9-68ca9240d8de">
                                                                            YALOVA
                                                                        </option>
                                                                        <option
                                                                            value="8f5ad39d-a45e-4668-8c25-aed094c0f521">
                                                                            YOZGAT
                                                                        </option>
                                                                        <option
                                                                            value="ab23d270-5f48-4dde-bc4e-8fb8a101d8f3">
                                                                            ZONGULDAK
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div
                                                                    className="col-sm-4 p-0 sub-banner-dropdown-button-container">
                                                                    <button
                                                                        className="btn btn-secondary btn-input w-100"
                                                                        style={{height: 48}}
                                                                        type="submit"
                                                                        id="baseTabletSite"
                                                                    >
                                                                        SİTEYE GİT
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="row sub-banner-dropdown-row m-0">
                                                                <div className="col-sm-3 p-0 ">
                                                                    <div>İlçe Başkanlığı:</div>
                                                                </div>
                                                                <div
                                                                    className="col-sm-4 p-0 sub-banner-dropdown-selectbox-container">
                                  <span className="subsite-selectbox-arrow">
                                    <img
                                        src={ArrowDownBlack}/>
                                  </span>
                                                                    <select
                                                                        className="select-control w-100"
                                                                        id="baseTabletDistrict"
                                                                        name="districtID"
                                                                    >
                                                                        <option>İlçe Seçiniz</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-sm-4"></div>
                                                            </div>
                                                            <input
                                                                name="__RequestVerificationToken"
                                                                type="hidden"
                                                                defaultValue="CfDJ8LNUMPBFiTlPuImaoqqW5kIoi1epHl9L4dnFZ6oDL8EgfHZMX_damJcit5KX6SRRxv-LiFChso-iQEYJUQxRoeKZwDWVNebVhdOOqpYp7uRHRksSgNJkzU4vnWmiulRLfJQ4Dx4N-ZOWn_L2ZVNZutM"
                                                            />
                                                        </form>
                                                    </div>
                                                    <div
                                                        className="sub-banner-dropdown-myk-list"
                                                        id="sub-banner-dropdown-myk-list"
                                                        style={{display: "none", overflowY: "scroll"}}
                                                        // className={`sub-banner-drop ${isMykMenuVisible ? 'displayNone' : 'displayBlock'}, overflowY : scroll`}
                                                    >
                                                        <div
                                                            className="d-flex align-items-baseline justify-content-start"
                                                            onClick="subbannerMykMenu()"
                                                        >
                              <span>
                                <img
                                    src={ExpandMore}/>
                              </span>{" "}
                                                            GERİ
                                                        </div>
                                                        <a
                                                            href="https://partiokulu.chp.org.tr"
                                                            target="_blank"
                                                        >
                                                            Parti Okulu
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="line"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-container-links">
                                    <div className="line"/>
                                    <a
                                        href="https://twitter.com/herkesicinchp"
                                        target="_blank"
                                        className="banner link-button icon"
                                    >
                                        <img
                                            src={TwitterIcon}
                                            alt="twitterIcon"
                                        />
                                    </a>
                                    <div className="line"/>
                                    <a
                                        href="www.facebook.com/chpingilterebirligi"
                                        target="_blank"
                                        className="banner link-button icon"
                                    >
                                        <img
                                            src={FacebookIcon}
                                            alt="facebookIcon"
                                        />
                                    </a>
                                    <div className="line"/>
                                    <a
                                        href="https://www.instagram.com/chpingilterebirligi/"
                                        target="_blank"
                                        className="banner link-button icon"
                                    >
                                        <img
                                            src={InstagramIcon}
                                            alt="instagramIcon"
                                        />
                                    </a>
                                    <div className="line"/>
                                    <a
                                        href="https://www.youtube.com/user/chpgenelmerkez"
                                        target="_blank"
                                        className="banner link-button icon"
                                    >
                                        <img
                                            src={YoutubeIcon}
                                            alt="youtubeIcon"
                                        />
                                    </a>
                                    <div className="line"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="red-line"/>
                </div>
                <div className="navigation-cage">
                    <div className="container">
                        <div className="row">
                            <div className="navigation-container d-flex">
                                <div className="navigation-container-left d-flex">
                                    <div className="navigation-container-left-logo">
                                        <a href="/">
                                            <img
                                                className="navigation-icon"
                                                src={SibSiteChp}
                                                alt="headerNavigationIcon"
                                            />
                                        </a>
                                    </div>
                                    <div className="navigation-container-left-content">
                                        <h2>İngiltere Birliği</h2>
                                        <div className="navigation-container-left-content-buttons">
                                            <a className="navigation link-button text" href="/">
                                                ANASAYFA
                                            </a>
                                            <div className="line"/>
                                            <a className="navigation link-button text" href="/gundem">
                                                HABERLER
                                            </a>
                                            <div className="line"/>
                                            <a
                                                href="https://chp.org.tr/yayinlar"
                                                className="navigation link-button text"
                                                target="_blank"
                                            >
                                                YAYINLAR
                                            </a>
                                            <div className="line"/>
                                            <div className="navigation-dropmenu">
                                                <a className="navigation link-button text">MEDYA</a>
                                                <div className="sub-navigation-drop">
                                                    <div className="sub-navigation-dropdown d-flex flex-column">
                                                        <a href="https://chp.org.tr/chp-tv" target="_blank">
                                                            CHP TV
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="line"/>
                                            <a
                                                className="navigation link-button text"
                                                href="/iletisim-bilgileri"
                                            >
                                                İLETİŞİM
                                            </a>
                                            <div className="line"/>
                                            <a
                                                className="navigation link-button text"
                                                href="/üyelik"
                                            >
                                                ÜYELİK
                                            </a>
                                            <div className="line d-none"/>
                                            <a className="navigation link-button icon d-none">
                                                <img
                                                    src={SubSitesearch}
                                                    alt="searchIcon"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="navigation-container-right d-flex">
                                    <img
                                        className="header-logo"
                                        src={SubSiteMustafaKemalAtaturkHq}
                                        alt="Ataturk"
                                    />
                                    <img
                                        className="header-logo"
                                        src={OzgulOzel}
                                        alt="ozgulOze;"
                                    />
                                    <div onClick={toggleSubbannerDropdownMenu}
                                         className={`navigation-container-right-navbar ${isDropdownOpen ? 'displayNone' : 'displayBlock'}`}/>
                                    <div onClick={toggleSubbannerDropdownMenu}
                                         className={`navigation-container-right-close ${isDropdownOpen ? 'displayBlock' : 'displayNone'}`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`navigation-cage-mobile ${isDropdownOpen ? 'displayBlock' : 'displayNone'}`}>
                        <div className="container">
                            <div className="row">
                                <div className="navigation-cage-mobile-main chp-mobile-menu-main">
                                    <div className="d-flex flex-column">
                                        <a href="/">ANASAYFA</a>
                                        <a href="/gundem">HABERLER</a>
                                        <a href="https://chp.org.tr/yayinlar">YAYINLAR</a>
                                        <a
                                            className="navigation-cage-mobile-media-dropmenu chp-mobile-menu-dropdown"
                                            data-target="#media"
                                        >
                                            MEDYA
                                        </a>
                                        <a href="/iletisim-bilgileri">İLETİŞİM</a>
                                        <a
                                                href="/üyelik"
                                            >
                                                ÜYELİK
                                            </a>
                                        <span className="navigation-cage-mobile-main-icon">
                      <img src={SubsiteSearchIcon}/>
                    </span>
                                        <input type="text" placeholder="Ara"/>
                                    </div>
                                </div>
                                <div id="media" className="navigation-cage-mobile-mediacage">
                                    <div className="d-flex flex-column">
                                        <div className="navigation-mobil-menu-back-icon subsite-back-icon-jq">
                      <span>
                        <img src={Back24}/>
                      </span>
                                            <a href="#">GERİ</a>
                                        </div>
                                        <a href="https://chp.org.tr/chp-tv" target="_blank">
                                            CHP TV
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
HeaderComponent.propTypes = {};
export default HeaderComponent;
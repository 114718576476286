import logo from './logo.svg';
import './App.css';
import {Foo} from "./components/foo";
import {QueryClient, QueryClientProvider} from 'react-query';
import Urls from "./Urls";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'assets/css/styles__ltr.css';
import 'assets/css/sweetalert2.css';
import 'assets/css/global.css';
import 'assets/css/subsite.css';
import 'assets/css/swiper-custom.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7L8aFl9LtR_4nlvGzHsWOrxh-JaupeoI",
  authDomain: "chp-ingiltere.firebaseapp.com",
  projectId: "chp-ingiltere",
  storageBucket: "chp-ingiltere.appspot.com",
  messagingSenderId: "204252800921",
  appId: "1:204252800921:web:aff053ddc67430026d1508",
  measurementId: "G-TEFY1WHL7L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// Create a client
const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                {/*<Foo/>*/}
                <Urls/>
                {/*<header className="App-header">*/}
                {/*  <img src={logo} className="App-logo" alt="logo" />*/}
                {/*  <p>*/}
                {/*    Edit <code>src/App.js</code> and save to reload.*/}
                {/*  </p>*/}
                {/*  <a*/}
                {/*    className="App-link"*/}
                {/*    href="https://reactjs.org"*/}
                {/*    target="_blank"*/}
                {/*    rel="noopener noreferrer"*/}
                {/*  >*/}
                {/*    Learn React*/}
                {/*  </a>*/}
                {/*</header>*/}
            </div>
        </QueryClientProvider>
    );
}

export default App;

// import Swiper core and required modules
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';

import {Swiper, SwiperSlide} from 'swiper/react';
import {useRef} from "react";

// import '../assets/css/styles__ltr.css';
// import '../assets/css/sweetalert2.css';
// import '../assets/css/global.css';
// import '../assets/css/swiper.css';
// import 'assets/css/swiper.css';
// import '../assets/css/swiper-custom.css';

// Import Swiper styles
// import '../assets/css/subsite.css';

const SwiperMobile = () => {
    const swiperRef = useRef(null); // Create a reference for the Swiper instance

    // Function to go to the next slide
    const goToNextSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    // Function to go to the previous slide
    const goToPrevSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    return (
        <Swiper
            className="col-lg-9 sub-slider "
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            // pagination={{clickable: true}}
            // scrollbar={{draggable: true}}
            onSwiper={(swiper) => (swiperRef.current = swiper)} // Store the Swiper instance
            onSlideChange={() => console.log('slide change')}
            autoplay={{
                delay: 5000, // Change slides every 2 seconds
                disableOnInteraction: false, // Resume autoplay after user interaction
            }}
        >
            <div className="swiper-custom-navigation">
                <a
                    className="sub-slider-arrow sub-slider-arrow-right sub-slide-next swiper-button-disabled"
                    tabIndex={-1}
                    role="button"
                    aria-label="Next slide"
                    aria-controls="swiper-wrapper-fabd5f86424cf820"
                    aria-disabled="true"
                    onClick={goToPrevSlide}
                />
                <a
                    className="sub-slider-arrow sub-slider-arrow-left sub-slide-prev"
                    tabIndex={0}
                    role="button"
                    aria-label="Previous slide"
                    aria-controls="swiper-wrapper-fabd5f86424cf820"
                    aria-disabled="false"
                    onClick={goToNextSlide}
                />

            </div>
            <SwiperSlide>
                <a
                    className="sub-slider-mobile-card"
                    href="/haberler/chp-lideri-ozel-spanyada-se-toplantisinda-konustu-neoliberal-duzenin-somurdugu-nsanlarimizin-yaninda-durmaliyiz"
                >
                    <picture>
                        <source
                            media="screen and (max-width:415px)"
                            srcSet="https://media.chp.org.tr/haberler/2024/2/24/d4e43db8-2564-4990-af8b-06e79cb42f55.jpg?h=283&w=432&a=top"
                        />
                        <source
                            media="screen and (max-width:768px)"
                            srcSet="https://media.chp.org.tr/haberler/2024/2/24/d4e43db8-2564-4990-af8b-06e79cb42f55.jpg?h=467&w=920&a=top"
                        />
                        <img
                            className="sub-slider-mobile-card-image"
                            src="https://media.chp.org.tr/haberler/2024/2/24/d4e43db8-2564-4990-af8b-06e79cb42f55.jpg?h=467&w=920&a=top"
                            alt="CHP ,Cumhuriyet Halk Partisi,Lider,Özgür Özel,Sosyalist Enternasyonal,Internacional Socialista,Genel Başkan,İspanya,Madrid,Spain,Filistin,Gazze,Palestine,Neoliberalizm"
                        />
                    </picture>
                    <div className="sub-slider-mobile-card-content">
                        <p className="sub-slider-mobile-card-content-date">
                            24.02.2024
                        </p>
                        <h2 className="sub-slider-mobile-card-content-title">
                            CHP Lideri Özel, İspanya'da SE Toplantısında Konuştu:
                            “Neoliberal Düzenin Sömürdüğü İnsanlarımızın Yanında
                            Durmalıyız”
                        </h2>
                    </div>
                </a>
            </SwiperSlide>
            <SwiperSlide>
                <a
                    className="sub-slider-mobile-card"
                    href="/haberler/chp-genel-baskani-ozgur-ozel-spanyada-turkiyenin-madrid-buyukelcisi-nukhet-kucukel-ezberciyi-ziyaret-etti"
                >
                    <picture>
                        <source
                            media="screen and (max-width:415px)"
                            srcSet="https://media.chp.org.tr/haberler/2024/2/23/acc67d27-04e1-4d9e-92ed-03c34a730529.jpg?h=283&w=432&a=top"
                        />
                        <source
                            media="screen and (max-width:768px)"
                            srcSet="https://media.chp.org.tr/haberler/2024/2/23/acc67d27-04e1-4d9e-92ed-03c34a730529.jpg?h=467&w=920&a=top"
                        />
                        <img
                            className="sub-slider-mobile-card-image"
                            src="https://media.chp.org.tr/haberler/2024/2/23/acc67d27-04e1-4d9e-92ed-03c34a730529.jpg?h=467&w=920&a=top"
                            alt="CHP ,Cumhuriyet Halk Partisi ,Genel Başkan,Özgür Özel,İspanya,Madrid,Sosyalist Enternasyonal,Socialist International,SE,Nükhet Küçükel Ezberci"
                        />
                    </picture>
                    <div className="sub-slider-mobile-card-content">
                        <p className="sub-slider-mobile-card-content-date">
                            23.02.2024
                        </p>
                        <h2 className="sub-slider-mobile-card-content-title">
                            CHP Genel Başkanı Özgür Özel, İspanya'da Türkiye’nin
                            Madrid Büyükelçisi Nükhet Küçükel Ezberci'yi Ziyaret Etti
                        </h2>
                    </div>
                </a>
            </SwiperSlide>
        </Swiper>
    );
};
SwiperMobile.propTypes = {};
export default SwiperMobile;
let API_SERVER_VAL = '';
let PUBLIC_URL_VAL = '';
let MEDIA_SERVER_VAL = '';
let REACT_APP_REACT_ADMIN_PANEL_URL_VAL = '';
let STORE_ID_VAL = '';
let STRIPEPUBLISABLEKEY = "";
let STRIPECHECKOUTPRICEREFERENCE = "";
let STRIPECUSTOMERPORTALURL = "";


switch (process.env.NODE_ENV) {
  case 'development':
    API_SERVER_VAL = process.env.REACT_APP_API_SERVER;
    MEDIA_SERVER_VAL = process.env.REACT_APP_MEDIA_SERVER;
    PUBLIC_URL_VAL = process.env.REACT_APP_PUBLIC_URL_VAL;
    REACT_APP_REACT_ADMIN_PANEL_URL_VAL = process.env.REACT_APP_REACT_ADMIN_PANEL_URL_VAL;
    STORE_ID_VAL = process.env.REACT_APP_STORE_ID_VAL;
    STRIPEPUBLISABLEKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    STRIPECHECKOUTPRICEREFERENCE = process.env.REACT_APP_STRIPE_CHECKOUT_PRICE_REFERENCE;
    STRIPECUSTOMERPORTALURL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
    break;
  case 'production':
    API_SERVER_VAL = process.env.REACT_APP_API_SERVER;
    MEDIA_SERVER_VAL = process.env.REACT_APP_MEDIA_SERVER;
    PUBLIC_URL_VAL = process.env.REACT_APP_PUBLIC_URL_VAL;
    REACT_APP_REACT_ADMIN_PANEL_URL_VAL = process.env.REACT_APP_REACT_ADMIN_PANEL_URL_VAL;
    STORE_ID_VAL = process.env.REACT_APP_STORE_ID_VAL;
    STRIPEPUBLISABLEKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    STRIPECHECKOUTPRICEREFERENCE = process.env.REACT_APP_STRIPE_CHECKOUT_PRICE_REFERENCE;
    STRIPECUSTOMERPORTALURL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
    //if env. does not continue with REACT_APP env variables does not work written on the documentation
    break;
  default:
    API_SERVER_VAL = 'http://localhost:8000';
    MEDIA_SERVER_VAL = 'http://localhost:8000';
    PUBLIC_URL_VAL = 'http://localhost:3000';
    REACT_APP_REACT_ADMIN_PANEL_URL_VAL = 'https://app.tabulaintegrations.com';
    STORE_ID_VAL = '1wrbfzsiq';
    STRIPEPUBLISABLEKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    STRIPECHECKOUTPRICEREFERENCE = process.env.REACT_APP_STRIPE_CHECKOUT_PRICE_REFERENCE;
    STRIPECUSTOMERPORTALURL = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
    break;
}


export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyD7L8aFl9LtR_4nlvGzHsWOrxh-JaupeoI",
    authDomain: "chp-ingiltere.firebaseapp.com",
    projectId: "chp-ingiltere",
    storageBucket: "chp-ingiltere.appspot.com",
    messagingSenderId: "204252800921",
    appId: "1:204252800921:web:aff053ddc67430026d1508",
    measurementId: "G-TEFY1WHL7L"
}


export const API_SERVER = API_SERVER_VAL;
export const PUBLIC_URL = PUBLIC_URL_VAL;
export const MEDIA_SERVER = MEDIA_SERVER_VAL;

export const REACT_ADMIN_PANEL_URL = REACT_APP_REACT_ADMIN_PANEL_URL_VAL;

export const STORE_ID = STORE_ID_VAL;

export const SESSION_DURATION = 5 * 3600 * 1000;

export const STRIPE_PUBLISHABLE_KEY = STRIPEPUBLISABLEKEY;
export const STRIPE_CHECKOUT_PRICE_REFERENCE = STRIPECHECKOUTPRICEREFERENCE;

export const STRIPE_CUSTOMER_PORTAL_URL = STRIPECUSTOMERPORTALURL;

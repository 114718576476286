// import 'bootstrap/dist/css/bootstrap.min.css';
import React, {lazy,Suspense} from "react";
// import {useSelector} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

// Css Import
import ScrollToTop from './helpers/ScrollToTop';

// const ErrorPage = lazy(() => import('./pages/404'));
// const AboutUs = lazy(() => import('./pages/AboutUs'));
const MainPage = lazy(() => import('./pages/MainPage'));
const Swiper = lazy(() => import('./components/Swiper'));
const LiveStreamComponent = lazy(() => import('./components/LiveStreamComponent'));
const Gundem = lazy(() => import('./pages/Gundem'));
const IletisimBilgileri = lazy(() => import('./pages/IletisimBilgileri'));
const Ozgecmis = lazy(() => import('./pages/Ozgecmis'));
const Uyelik = lazy(() => import('./pages/Uyelik'));


function Urls() {


  return (
    <Router>
      {/*<ScrollToTop>*/}
        <Suspense
          fallback={
            <div className="">
              <div className="">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >

          <Routes>
            <Route path={process.env.PUBLIC_URL + "/"} element={<MainPage/>}/>
            <Route path={process.env.PUBLIC_URL + "/ana-sayfa"} element={<MainPage/>}/>
            <Route path={process.env.PUBLIC_URL + "/swiper"} element={<Swiper/>}/>
            <Route path={process.env.PUBLIC_URL + "/LiveStreamComponent"} element={<LiveStreamComponent/>}/>
            <Route path={process.env.PUBLIC_URL + "/gundem"} element={<Gundem/>}/>
            <Route path={process.env.PUBLIC_URL + "/gundem/:topic"} element={<Gundem/>}/>
            <Route path={process.env.PUBLIC_URL + "/iletisim-bilgileri"} element={<IletisimBilgileri/>}/>
            <Route path={process.env.PUBLIC_URL + "/ozgecmis/mehmet-sar"} element={<Ozgecmis/>}/>
            <Route path={process.env.PUBLIC_URL + "/üyelik"} element={<Uyelik/>}/>
            {/*<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage/>}/>*/}
            {/*<Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon/>}/>*/}
            {/*<Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy/>}/>*/}
            {/*<Route path={process.env.PUBLIC_URL + "/terms-use"} element={<TermsOfUse/>}/>*/}

          </Routes>
        </Suspense>
      {/*</ScrollToTop>*/}
    </Router>
  );
}

export default Urls;

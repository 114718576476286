import React from 'react';
import PropTypes from 'prop-types';
import SubSiteFooter from "../assets/img/subSiteFooter.png";
import SibSiteChp from "../assets/img/subSiteCHP-Dikey-Logo.png";

const FooterComponent = () => {
    return (
        <>
            <div className="container newsletter-subscription">
                <div className="row">
                    <div className="col-lg-12 mx-auto newsletter-subscription-content">
                        <div className="newsletter-subscription-logo">
                            <img
                                className="newsletter-subscription-image"
                                src={SibSiteChp}
                                alt="chp logo"
                            />
                        </div>
                        <div className="row">
                            <div className="newsletter-subscription-content col-lg-10 col-sm-10 mx-auto text-center">
                                <h2 className="newsletter-subscription-content-title">
                                    CHP E-BÜLTEN ÜYELİĞİ
                                </h2>
                                <p className="newsletter-subscription-content-text col-sm-10 mx-auto">
                                    CHP ile ilgili tüm gelişmelerden anında haberdar olmak için;
                                    “CHP e-Bülten” listesine kayıt olunuz.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 col-10 mx-auto">
                                <form
                                    id="newsletterForm"
                                    className="newsletter-subscription-input row form"
                                    onSubmit="return false;"
                                    noValidate="novalidate"
                                >
                                    <div className="form-group col-lg-2">
                                        <label htmlFor="formControlName" className="form-label">
                                            Adı:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            id="formControlName"
                                            placeholder="Adı"
                                            required=""
                                            data-msg-required="Lütfen adınızı giriniz"
                                        />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label htmlFor="formControlLastName" className="form-label">
                                            Soyadı:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formControlLastName"
                                            placeholder="Soyadı"
                                            required=""
                                            data-msg-required="Lütfen soyadınızı giriniz"
                                        />
                                    </div>
                                    <div className="form-group col-lg-5">
                                        <label htmlFor="formControlMail" className="form-label">
                                            E-Posta Adresi:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mail-input"
                                            id="formControlMail"
                                            name="email"
                                            placeholder="E-Posta adresinizi buraya yazınız"
                                            required=""
                                            data-msg-required="Lütfen e-posta adresinizi giriniz"
                                            data-msg-email="Lütfen geçerli bir e-posta adresi giriniz"
                                        />
                                    </div>
                                    <button
                                        id="newsletterRegister"
                                        className="btn newsletter-subscription-btn btn-secondary"
                                    >
                                        KAYDET
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="col-lg-12 footer-cage d-flex align-items-center">
                    <div className="container">
                        <div className="footer-cage-content">
                            <div className="footer-logo">
                                <img
                                    src={SubSiteFooter}
                                    alt="FooterLogo"
                                />
                            </div>
                            <div className="footer-text m-4">
                                <p>© 2024 Tüm Hakları Saklıdır.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div id="fb-root" className=" fb_reset">
                <div
                    style={{position: "absolute", top: "-10000px", width: 0, height: 0}}
                >
                    <div/>
                </div>
            </div>
        </>
    );
};
FooterComponent.propTypes = {};
export default FooterComponent;
import TwitterIcon from '../assets/svg/subSiteTwitter.svg';
import FacebookIcon from '../assets/svg/subSiteFacebook.svg';
import InstagramIcon from '../assets/svg/subSiteInstagram.svg';
import YoutubeIcon from '../assets/svg/subSiteYoutube.svg';
import SubSiteMustafaKemalAtaturk from '../assets/img/subSiteMustafaKemalAtaturk-1.png';
import OzgulOzel from '../assets/img/ozgur-ozel-2.jpg';
import SibSiteChp from '../assets/img/subSiteCHP-Dikey-Logo.png';
import SubSiteCardMail from '../assets/svg/subSiteCardMail.svg';
import MehmetSar from 'assets/img/mehmet-sar.jpg';
import MehmetSar204220 from 'assets/img/mehmet-sar-204-220.jpg';
import MehmetSar204322 from 'assets/img/mehmet-sar-204-322.jpg';


import MehmetSar159251 from 'assets/img/mehmet-sar-159-251.jpg';
import MehmetSar208327 from 'assets/img/mehmet-sar-208-327.jpg';
import MehmetSar220347 from 'assets/img/mehmet-sar-220-347.jpg';
import MehmetSar294467 from 'assets/img/mehmet-sar-294-467.jpg';
import MehmetSar284455 from 'assets/img/mehmet-sar-284-455.jpg';
import MehmetSar441571 from 'assets/img/mehmet-sat-441-574.jpg';
import MehmetSar347452 from 'assets/img/mehmet-sar-347-452.jpg';
import ArrowDownBlack from 'assets/svg/arrow-down-black.svg';
import Back24 from 'assets/svg/back-24px.svg';
import ExpandMore from 'assets/svg/expand_more-white.svg';
import SubSitesearch from 'assets/svg/subSitesearch-24px.svg';
import SubsiteSearchIcon from 'assets/svg/subsite-search-icon.svg';
import SubSiteFooter from 'assets/img/subSiteFooter.png';
import SwiperC from "./Swiper";
import SwiperMobile from "./SwiperMobile";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import ChpMetaComponent from "./ChpMetaComponent";
// import 'assets/js/swiper.js'


export const Foo = () => (
    <>
        <ChpMetaComponent/>
        <div id="root">
            <HeaderComponent/>
            {/*<CustomSwiperComponent/>*/}
            <div className="container sub-slider-hero-section">
                <div className="row">
                <SwiperC/>
                    <div className="col-sm-12 col-lg-3 sub-pre">
                        <div className="sub-pre-card">
                            <a style={{textDecoration: "none"}} href="/ozgecmis/mehmet-sar">
                                <picture>
                                    <source
                                        media="screen and (max-width:376px)"
                                        srcSet={MehmetSar284455}
                                    />
                                    <source
                                        media="screen and (max-width:415px)"
                                        srcSet={MehmetSar284455}
                                    />
                                    <source
                                        media="screen and (max-width:540px)"
                                        srcSet={MehmetSar284455}
                                    />
                                    <source
                                        media="screen and (max-width:720px)"
                                        srcSet={MehmetSar284455}
                                    />
                                    <source
                                        media="screen and (max-width:768px)"
                                        srcSet={MehmetSar284455}
                                    />
                                    <source
                                        media="screen and (max-width:1024px)"
                                        srcSet={MehmetSar284455}
                                    />
                                    <img
                                        className="sub-pre-card-image"
                                        src={MehmetSar284455}
                                        alt="Mehmet Sar"
                                    />
                                </picture>
                                <h3>Mehmet Şar</h3>
                                <h6>İngiltere Birliği</h6>
                            </a>
                            <div className="sub-pre-card-icons">
                                <a
                                    href="mailto:ingiltere@chp.org.tr"
                                    target="_blank"
                                    className="navigation link-button icon"
                                >
                                    <img
                                        src={SubSiteCardMail}
                                        alt="MailIcon"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="container">
                            <div className="sub-pre-card-tablet">
                                <div className="d-flex">
                                    <a
                                        style={{textDecoration: "none"}}
                                        href="/ozgecmis/mehmet-sar"
                                    >
                                        <div className="sub-pre-card-tablet-img">
                                            <picture>
                                                <source
                                                    media="screen and (max-width:376px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:415px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:540px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:720px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:768px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:1024px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <img
                                                    src={MehmetSar159251}
                                                    alt="Mehmet Şar"
                                                />
                                            </picture>
                                        </div>
                                    </a>
                                    <div className="sub-pre-card-tablet-content">
                                        <a
                                            style={{textDecoration: "none"}}
                                            href="/ozgecmis/mehmet-sar"
                                        >
                                            <h3>Mehmet Şar</h3>
                                            <h6>İngilte Birliği</h6>
                                        </a>
                                        <div className="sub-pre-card-tablet-icons">
                                            <a
                                                style={{textDecoration: "none"}}
                                                href="/ozgecmis/mehmet-sar"
                                            ></a>
                                            <div className="sub-pre-card-tablet-icons-content d-flex">
                                                <a
                                                    style={{textDecoration: "none"}}
                                                    href="/ozgecmis/mehmet-sar"
                                                ></a>
                                                <a
                                                    href="mailto:ingiltere@chp.org.tr"
                                                    target="_blank"
                                                    className="navigation link-button icon"
                                                >
                                                    <img
                                                        src={SubSiteCardMail}
                                                        alt="MailIcon"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-pre-card-mobile">
                                <div className="sub-pre-card-mobile-cage">
                                    <a
                                        style={{textDecoration: "none"}}
                                        href="/ozgecmis/mehmet-sar"
                                    >
                                        <div className="sub-pre-card-mobile-cage-top d-flex">
                                            <div className="sub-pre-card-mobile-cage-top-img">
                                                <picture>
                                                    <source
                                                        media="screen and (max-width:376px)"
                                                        srcSet={MehmetSar159251}
                                                    />
                                                    <source
                                                        media="screen and (max-width:415px)"
                                                        srcSet={MehmetSar159251}
                                                    />
                                                    <source
                                                        media="screen and (max-width:540px)"
                                                        srcSet={MehmetSar159251}
                                                    />
                                                    <source
                                                        media="screen and (max-width:720px)"
                                                        srcSet={MehmetSar159251}
                                                    />
                                                    <source
                                                        media="screen and (max-width:768px)"
                                                        srcSet={MehmetSar159251}
                                                    />
                                                    <source
                                                        media="screen and (max-width:1024px)"
                                                        srcSet={MehmetSar159251}
                                                    />
                                                    <img
                                                        src={MehmetSar159251}
                                                        alt="Mehmet Şar"
                                                    />
                                                </picture>
                                            </div>
                                            <div className="sub-pre-card-mobile-cage-top-content">
                                                <h3>Mehmet Şar</h3>
                                                <h6>İngiltere Birliği</h6>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="sub-pre-card-mobile-cage-bottom">
                                        <a
                                            style={{textDecoration: "none"}}
                                            href="/ozgecmis/mehmet-sar"
                                        ></a>
                                        <div className="sub-pre-card-mobile-cage-bottom-icons">
                                            <a
                                                style={{textDecoration: "none"}}
                                                href="/ozgecmis/mehmet-sar"
                                            ></a>
                                            <div className="sub-pre-card-mobile-cage-bottom-icons-content d-flex">
                                                <a
                                                    style={{textDecoration: "none"}}
                                                    href="/ozgecmis/mehmet-sar"
                                                ></a>
                                                <a
                                                    href="mailto:ingiltere@chp.org.tr"
                                                    target="_blank"
                                                    className="navigation link-button icon"
                                                >
                                                    <img
                                                        src={SubSiteCardMail}
                                                        alt="MailIcon"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-slider-mobile-hidden">
                <div className="col-sm-12 col-lg-9 sub-slider-mobile">
                    <SwiperMobile/>
                </div>
                <div className="col-sm-12 col-lg-3 sub-pre">
                    <div className="sub-pre-card">
                        <a style={{textDecoration: "none"}} href="/ozgecmis/mehmet-sar">
                            <picture>
                                <source
                                    media="screen and (max-width:376px)"
                                    srcSet={MehmetSar204220}
                                />
                                <source
                                    media="screen and (max-width:415px)"
                                    srcSet={MehmetSar204322}
                                />
                                <source
                                    media="screen and (max-width:540px)"
                                    srcSet={MehmetSar159251}
                                />
                                <source
                                    media="screen and (max-width:720px)"
                                    srcSet={MehmetSar159251}
                                />
                                <source
                                    media="screen and (max-width:768px)"
                                    srcSet={MehmetSar208327}
                                />
                                <source
                                    media="screen and (max-width:1024px)"
                                    srcSet={MehmetSar220347}
                                />
                                <img
                                    className="sub-pre-card-image"
                                    src={MehmetSar284455}
                                    alt="Mehmet Şar"
                                />
                            </picture>
                            <h3>Mehmet Şar</h3>
                            <h6>İngilter Birliği</h6>
                        </a>
                        <div className="sub-pre-card-icons">
                            <div className="sub-pre-card-icons-content d-flex">
                                <a
                                    href="mailto:ingiltere@chp.org.tr"
                                    target="_blank"
                                    className="navigation link-button icon"
                                >
                                    <img
                                        src={SubSiteCardMail}
                                        alt="MailIcon"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="sub-pre-card-tablet">
                            <div className="d-flex">
                                <a
                                    style={{textDecoration: "none"}}
                                    href="/ozgecmis/mehmet-sar"
                                >
                                    <div className="sub-pre-card-tablet-img">
                                        <picture>
                                            <source
                                                media="screen and (max-width:376px)"
                                                srcSet={MehmetSar159251}
                                            />
                                            <source
                                                media="screen and (max-width:415px)"
                                                srcSet={MehmetSar159251}
                                            />
                                            <source
                                                media="screen and (max-width:540px)"
                                                srcSet={MehmetSar159251}
                                            />
                                            <source
                                                media="screen and (max-width:720px)"
                                                srcSet={MehmetSar159251}
                                            />
                                            <source
                                                media="screen and (max-width:768px)"
                                                srcSet={MehmetSar159251}
                                            />
                                            <source
                                                media="screen and (max-width:1024px)"
                                                srcSet={MehmetSar159251}
                                            />
                                            <img
                                                src={MehmetSar159251}
                                                alt="Mehmet Şar"
                                            />
                                        </picture>
                                    </div>
                                </a>
                                <div className="sub-pre-card-tablet-content">
                                    <a
                                        style={{textDecoration: "none"}}
                                        href="/ozgecmis/mehmet-sar"
                                    >
                                        <h3>Mehmet Şar</h3>
                                        <h6>İngiltere Birliği</h6>
                                    </a>
                                    <div className="sub-pre-card-tablet-icons">
                                        <a
                                            style={{textDecoration: "none"}}
                                            href="/ozgecmis/mehmet-sar"
                                        ></a>
                                        <div className="sub-pre-card-tablet-icons-content d-flex">
                                            <a
                                                style={{textDecoration: "none"}}
                                                href="/ozgecmis/mehmet-sar"
                                            ></a>
                                            <a
                                                href="mailto:ingiltere@chp.org.tr"
                                                target="_blank"
                                                className="navigation link-button icon"
                                            >
                                                <img
                                                    src={SubSiteCardMail}
                                                    alt="MailIcon"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub-pre-card-mobile">
                            <div className="sub-pre-card-mobile-cage">
                                <a
                                    style={{textDecoration: "none"}}
                                    href="/ozgecmis/mehmet-sar"
                                >
                                    <div className="sub-pre-card-mobile-cage-top d-flex">
                                        <div className="sub-pre-card-mobile-cage-top-img">
                                            <picture>
                                                <source
                                                    media="screen and (max-width:376px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:415px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:540px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:720px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:768px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <source
                                                    media="screen and (max-width:1024px)"
                                                    srcSet={MehmetSar159251}
                                                />
                                                <img
                                                    src={MehmetSar159251}
                                                    alt="Mehmet Şar"
                                                />
                                            </picture>
                                        </div>
                                        <div className="sub-pre-card-mobile-cage-top-content">
                                            <h3>Mehmet Şar</h3>
                                            <h6>İngiltere Birliği</h6>
                                        </div>
                                    </div>
                                </a>
                                <div className="sub-pre-card-mobile-cage-bottom">
                                    <div className="sub-pre-card-mobile-cage-bottom-icons">
                                        <div className="sub-pre-card-mobile-cage-bottom-icons-content d-flex">
                                            <a
                                                href="mailto:ingiltere@chp.org.tr"
                                                target="_blank"
                                                className="navigation link-button icon"
                                            >
                                                <img
                                                    src={SubSiteCardMail}
                                                    alt="MailIcon"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent/>
        </div>
        <div>
            <div
                className="grecaptcha-badge"
                data-style="bottomright"
                style={{
                    width: 256,
                    height: 60,
                    display: "block",
                    transition: "right 0.3s ease 0s",
                    position: "fixed",
                    bottom: 14,
                    right: "-186px",
                    boxShadow: "gray 0px 0px 5px",
                    borderRadius: 2,
                    overflow: "hidden"
                }}
            >
                <div className="grecaptcha-logo">
                    <iframe
                        title="reCAPTCHA"
                        width={256}
                        height={60}
                        role="presentation"
                        name="a-cek8v8qjinzf"
                        frameBorder={0}
                        scrolling="no"
                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                        src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6Ld9YIAbAAAAAIfRDsjNG_oK4zzeX2F1XRXyFr8l&co=aHR0cHM6Ly9hbG1hbnlhLWZyYW5rZnVydC5jaHAub3JnLnRyOjQ0Mw..&hl=en&v=1kRDYC3bfA-o6-tsWzIBvp7k&size=invisible&cb=nl1hj8ro93ou"
                    />
                </div>
                <div className="grecaptcha-error"/>
                <textarea
                    id="g-recaptcha-response-100000"
                    name="g-recaptcha-response"
                    className="g-recaptcha-response"
                    style={{
                        width: 250,
                        height: 40,
                        border: "1px solid rgb(193, 193, 193)",
                        margin: "10px 25px",
                        padding: 0,
                        resize: "none",
                        display: "none"
                    }}
                    defaultValue={""}
                />
            </div>
            <iframe style={{display: "none"}}/>
            <script src="assets/js/select2.min.js"></script>
            <script src="assets/js/pagination.js"></script>
            <script src="assets/js/submenu.js"></script>
            <script src="assets/js/subsite-header.js"></script>
            <script src="assets/js/swiper.min.js"></script>
            <script src="assets/js/swiper.js"></script>
            <script src="assets/js/sub-slider.js"></script>
            <script src="assets/js/sweetalert2.all.min.js"></script>
            <script src="assets/js/bannerdropdown.js"></script>
        </div>
    </>
)
